<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">FARMERS</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button
          v-b-modal.upload-csv
          squared
          variant="outline-primary"
          class="mr-1"
          v-analytics:click="['import_farmer_csv', { element: 'Suppliers' }]"
          :disabled="!can('modify', 'suppliers')"
        >
          <b-icon-plus></b-icon-plus> Import Farmers from Csv
        </b-button>
        <b-button
          squared
          variant="primary"
          v-b-modal.add-farmer
          v-analytics:click="['add_new_farmer', { element: 'Suppliers' }]"
          :disabled="!can('modify', 'suppliers')"
        >
          <b-icon-plus></b-icon-plus> New Farmer
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <b-row class="m-0 w-100">
        <b-col>
          <b-select
            class="form-select mr-2"
            v-model="chosenCrop"
            @change="findByCrop"
            v-analytics:change="[
              'filter_farmer_produce',
              { element: 'Suppliers' },
            ]"
            :options="crops"
          >
            <template #first>
              <b-select-option selected :value="null"
                >All Produce</b-select-option
              >
            </template>
          </b-select>

          <b-form class="d-inline-block">
            <b-form-input
              placeholder="Search Name"
              type="search"
              class="search"
              v-analytics:input="[
                'search_farmer_name',
                { element: 'Suppliers' },
              ]"
              @input="findByName"
              v-model="searchfield"
              v-capitalize
            ></b-form-input>
          </b-form>
        </b-col>
        <b-col cols="auto">
          <div class="sidebar-width text-right">
            <button
              class="btn-opt primary"
              @click="reload"
              v-analytics:click="[
                'refresh_farmer_page',
                { element: 'Suppliers' },
              ]"
            >
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button
              class="btn-opt primary"
              v-print="'#farmerTable'"
              v-analytics:click="['print_farmers', { element: 'Suppliers' }]"
            >
              <b-icon-printer></b-icon-printer> Print
            </button>
            <download-excel
              class="btn btn-opt primary"
              :fetch="loadAll"
              v-analytics:click="['export_farmers', { element: 'Suppliers' }]"
              :fields="csvFields"
              type="csv"
              :escapeCsv="false"
            >
              <b-icon-book v-show="haveLoaded"></b-icon-book>
              <b-spinner small variant="primary" v-show="!haveLoaded"></b-spinner>
               Export
            </download-excel>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="container-fluid">
      <div class="text-capitalize">
        <div class="text-capitalize">
          <b-table
            id="farmerTable"
            :fields="fields"
            :items="farmerData"
            :busy="isLoadingFarmers"
            thead-class="bg-primary-tb text-primary-tb border-primary-tb"
            @row-clicked="getFarmerDetails"
            show-empty
          >
            <template #empty>
              <div class="text-center">{{message}}</div>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(time)="data">
              {{ formatDate(data.item.time) }}
            </template>
            <template #cell(farmer_name)="data">
              <b-link @click="getFarmerDetails(data.item)">
                {{ data.item.farmer_name }}</b-link
              >
            </template>
            <template #cell(farmer_group)="data">
              {{ print_group(data.item.farmer_group) }}
            </template>
          </b-table>
          <div class="d-flex align-items-center">
            <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            pills
            aria-controls="farm-tab"
            class="b-pagination-class mr-2"
          ></b-pagination>
          <div class="mt-2 pl-2" v-show="!isLoadingFarmers">Showing {{1+offset}} - {{offset+farmerData.length}} of {{rows}}</div>
          </div>

        </div>
      </div>
    </div>
    <upload-farmer></upload-farmer>
    <add-farmer
      @AddFarmerSuccess="onFarmerAdded"
      :crops-data="crops"
    ></add-farmer>
    <profile-modal></profile-modal>
  </div>
</template>

<script>
import ProfileModal from "../../../../components/farmer_modal/Farmer.vue";
import UploadFarmer from "../../../../components/farmer_modal/UploadFarmer.vue";
import * as csvApi from "../../../../components/farmer_modal/api";
import { bus } from "@/main.js";
import moment from "moment";
import { can } from "../../../auth/utils";
import { project_farmers,farmers_per_project } from "./api.calls";
import {STATUS} from "./ui.utils";
// import { fields as csv_fields } from "./ui.utils";
import { NewFarmer } from "./mixins";
import * as csvParser from "papaparse";
var _ = require("lodash");
const DELAY = 1500;
function fmt_meta(meta){
  if(meta["type"]=="multichoice"){
    return meta["value"].join(", ");
  }
  return meta["value"];
}
/**
 * @typedef GP
 * @property {string} name
 */
export default {
  components: { UploadFarmer, ProfileModal },
  mixins: [NewFarmer],
  data() {
    let fields = [
      { key: "farmer_name", label: "Name" },
      { key: "farmer_phone_number", label: "Contact" },
      { key: "acreage", label: "Size" },
      { key: "value_chain", label: "Produce" },
      { key: "farmer_group", label: "Group" },
      { key: "time", label: "Date Added" },
    ];

    /**
     * @param {string | GP } gp
     */
    const print_group = (gp) => {
      return gp !== undefined ? gp.name : "NA";
    };
    return {
      searchfield: null,
      fields,
      farmerData: [],
      message: "",
      isLoadingFarmers: false,
      farmerId: "",
      chosenCrop:null,
      perPage: 12,
      currentPage: 1,
      csvFields: {},
      print_group,
      rows: 0,
      haveLoaded: true,
      cache:true,
      filter:null,
    };
  },
  

  directives:{
    capitalize: {
        update(el) {
        const lowerCase = el.value.toLowerCase()
        el.value = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
      },
    },
  },

  mounted: function () {
    this.initFields();
    this.getFarmersPerProject();
    this.setCropsToSelect();
    this.$analytics.track("open_farmers", { element: "Suppliers" });
    this.filter = this.baseDebouncer();
  },

  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
    isFiltering(){
      return this.searchfield || this.chosenCrop;
    },
    offset(){
      return this.perPage * (this.currentPage-1)
    }
  },
  watch:{
    currentPage(){
      this.getFarmersPerProject();
    }
  },

  methods: {
    moment: moment,
    can,
    initFields() {
      csvApi.getcsvtemplate().then((res) => {
        const { data } = csvParser.parse(res.data);
        let fields = {};
        for (let k of data[0]) {
          fields[k] = k;
        }
        fields["farmer_group"] = {
          field: "farmer_group",
          callback: (value) => {
            return value.name;
          },
        };
        this.csvFields = fields;
      });
    },
    onFarmerAdded() {
      this.getFarmersPerProject();
      this.currentPage = 1;
    },
    reload() {
      this.cache = false;
      this.getFarmersPerProject();
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("D MMMM YYYY");
    },
    addMetaFields(farmers){
      const metafarmers = farmers.filter(el=>el.has_metadata);
      let labels = [];
      metafarmers.map(el=>{
        for(let k of el['metadata']){
          el['meta'] = {};
          el['meta'][k.label] = k;
          labels.push(k.label);
        }
      });
      let cfields = {};
      labels.map(el=>{
        cfields[el] = {
          field:"meta."+el,
          callback:(value)=>{
            return fmt_meta(value)
          }
        }
      });
      this.csvFields = {...this.csvFields,...cfields};
    },
    async getFarmersPerProject() {
      this.isLoadingFarmers = true;
      const result = await project_farmers(this.projectId, {
        limit: this.perPage,
        farmer_name:this.formatName(this.searchfield),
        value_chain:this.chosenCrop,
        offset: this.offset,
      },this.cache);
      if(this.offset==0)this.rows = result.headers["x-total-count"];
      
      this.farmerData = result.data;
      this.message = this.isFiltering && result.data.length==0?STATUS.MISSING:STATUS.EMPTY;
      this.isLoadingFarmers = false;
    },
    getFarmerDetails(item) {
      bus.$emit("farmer-view", {
        name: item.farmer_name,
        data: item,
        farmer_id: item.farmer_id,
        tab: "profile",
      });
    },
    async loadAll() {
      this.haveLoaded = false;
      const result = await farmers_per_project(this.projectId);
      this.$nextTick(() => {
        this.haveLoaded = true;
      });
      return result;
    },
    baseDebouncer(){
      return _.debounce(()=>{
        this.getFarmersPerProject();
      },DELAY)
    },
    debouncer(){
      return _.debounce(()=>{
          this.currentPage = 1;
        },DELAY);
    },
    findByName(){
      //Terminate pending query
      this.filter.cancel();
      // Watcher will not triggered if currentPage was already 1
      // 
      if(this.currentPage == 1){
        this.filter = this.baseDebouncer(); 
      }else{
        this.filter = this.debouncer();
      }
      this.filter();
    },
    findByCrop(){
      // Watcher will not triggered if currentPage was already 1
      if(this.currentPage==1){
        this.getFarmersPerProject();
        return 0;
      }
      // To trigger watcher if currentPage was not 1.
      this.currentPage = 1;
    },
    //Title case name for caching.
    formatName(str){
      if(!str){
        return null;
      }
      return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())
    },
    onEditing() {
      
    },
  },
};
</script>

<style>
.pagination.b-pagination-class {
  margin-top: 20px;
}
</style>
