<template>
  <b-modal id="upload-csv" size="md" title="Upload Farmers" hide-footer>
    <b-row>
      <b-container>
        <h4 class="dark font-weight-bold">STEP 1</h4>
        <h5 class="dark font-weight-light">Download CSV from template</h5>
        <b-button
          class="btn btn-primary"
          v-analytics:click="[
            'download_farmer_template',
            { element: 'Suppliers' }
          ]"
          @click="getTemplate"
        >
          <b-icon icon="download" aria-hidden="true"></b-icon> Download template
        </b-button>
        <hr />
        <h4 class="dark font-weight-bold">STEP 2</h4>
        <h5 class="dark font-weight-light">
          Open the template with Excel, Fill the form columns with farmers
          details and save the file
        </h5>
        <hr />
        <h4 class="dark font-weight-bold">STEP 3</h4>
        <h5 class="dark font-weight-light">Upload the .csv file</h5>
        <div>
          <hr />
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            accept=".csv"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            v-analytics:change="['attach_farmer_csv', { element: 'Suppliers' }]"
          ></b-form-file>
          <hr />
          <b-alert v-model="faulty" dismissible variant="warning">
            <h6>Done with errors</h6>
            <ul>
              <li v-for="(item, idx) in row_errors" :key="idx">
                {{ item | fmt_err }}
              </li>
            </ul>
            <download-excel
              class="font-weight-bold btn btn-link"
              type="csv"
              :data="faulty_rows"
            >
              Download failed rows</download-excel
            >
          </b-alert>
          <b-button
            squared
            variant="success"
            class="mr-1"
            @click="submitFile"
            v-show="file"
          >
            <b-icon
              icon="upload"
              aria-hidden="true"
              v-show="!isLoading"
            ></b-icon>
            <b-spinner v-if="isLoading" small label="Small Spinner"></b-spinner>
            Upload File
          </b-button>
        </div>
      </b-container>
    </b-row>
  </b-modal>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
import { getCurrentSeason } from "@/components/add_produce/actions.js";
import * as api from "./api";
export default {
  name: "upload-farmer",
  data() {
    return {
      baseURI: process.env.VUE_APP_BACKEND,
      file: null,
      isLoading: false,
      isLarge: false,
      json_data: [{}],
      form: {
        project_id: null,
        season: getCurrentSeason(),
      },
      error: null,
      row_errors: [],
      faulty_rows: [],
      faulty: false,
    };
  },
  filters: {
    fmt_err: (row) => {
      return row[0] + "(" + row[1] + ")";
    },
  },
  methods: {
    uploadFile() {
      this.file = this.$refs.file.files[0];
    },
    getTemplate(){
      api.getcsvtemplate().then((response)=>{
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'addFarmer.csv';
        link.click();
      })
    },
    onReset() {
      this.file = null;
      this.$bvModal.hide("upload-csv");
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    submitFile() {
      let self = this;
      let formData = new FormData();
      formData.append("file", this.file);
      this.isLoading = true;
      axiosApiInstance
        .post(this.baseURI + "/api/v3/farmers/farmer-upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: { project_id: this.projectId },
        })
        .then((result) => {
          this.message = "File successfully submitted for upload";
          self.$vToastify.success("File successfully submitted for upload");
          this.isLoading = false;
          this.faulty = this.faulty = result.data.row_errors.length!=0;
          this.row_errors = result.data.row_errors;
          this.faulty_rows = result.data.faulty_rows;
          setTimeout(() => {
            this.onReset();
          }, 10000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = error;
          self.$vToastify.error("Something went wrong please try again");
        });
    },
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
};
</script>

